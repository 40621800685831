export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132')
];

export const server_loads = [0,8,2,18,4,6,24];

export const dictionary = {
		"/": [~33],
		"/admin": [~55,[8],[9]],
		"/admin/app-reviews": [56,[8],[9]],
		"/admin/app-reviews/view/[app_id]/details": [57,[8],[9]],
		"/admin/applications": [58,[8],[9]],
		"/admin/applications/[application_id]": [59,[8,10],[9]],
		"/admin/dashboard": [60,[8,11],[9]],
		"/admin/dashboard/latest-participants": [61,[8,11],[9]],
		"/admin/dashboard/latest-researchers": [62,[8,11],[9]],
		"/admin/participants": [63,[8],[9]],
		"/admin/participants/view/[participant_id]": [64,[8,12],[9]],
		"/admin/participants/view/[participant_id]/(tabs)/details": [65,[8,12,13],[9]],
		"/admin/payouts": [66,[8],[9]],
		"/admin/projects": [67,[8],[9]],
		"/admin/projects/edit/[id]": [68,[8,14],[9]],
		"/admin/projects/edit/[id]/criteria": [69,[8,14],[9]],
		"/admin/projects/edit/[id]/details": [70,[8,14],[9]],
		"/admin/projects/edit/[id]/questions": [71,[8,14],[9]],
		"/admin/projects/view/[project_id]/details": [72,[8,15],[9]],
		"/admin/projects/view/[project_id]/messages": [73,[8,15],[9]],
		"/admin/projects/view/[project_id]/participants": [74,[8,15],[9]],
		"/admin/projects/view/[project_id]/participants/[application_id]": [75,[8,15,16],[9]],
		"/admin/projects/view/[project_id]/participants/[application_id]/chatroom/[chatroom_id]": [76,[8,15,16,17],[9]],
		"/admin/projects/view/[project_id]/transactions": [77,[8,15],[9]],
		"/admin/referred-participants": [78,[8],[9]],
		"/admin/researchers": [79,[8],[9]],
		"/admin/researchers/special-offers": [80,[8],[9]],
		"/admin/tech-stack/[participant_id]": [81,[8],[9]],
		"/admin/transactions": [82,[8],[9]],
		"/(auth)/auth/accept-invite": [~34,[2],[3]],
		"/(auth)/auth/forgot-password": [35,[2],[3]],
		"/(auth)/auth/linkedin/callback": [~36,[2],[3]],
		"/(auth)/auth/login": [37,[2],[3]],
		"/(auth)/auth/logout": [~38,[2],[3]],
		"/(auth)/auth/reset-password": [39,[2],[3]],
		"/(auth)/auth/signup": [40,[2],[3]],
		"/participant-login": [~101],
		"/participant": [83,[18],[19]],
		"/(nomenu)/participant/account": [47,[4,6],[5]],
		"/(nomenu)/participant/complete-profile": [~48,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/business": [~49,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/details": [50,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/note": [51,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/pending-review": [52,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/personal": [~53,[4,6],[5,,7]],
		"/(nomenu)/participant/complete-profile/professional": [~54,[4,6],[5,,7]],
		"/participant/confirm-work-email/[token]": [84,[18],[19]],
		"/participant/dashboard": [85,[18],[19]],
		"/(auth)/participant/login": [41,[2],[3]],
		"/(auth)/participant/login/[token]": [42,[2],[3]],
		"/participant/messages": [86,[18,20],[19]],
		"/participant/messages/[chatroom_id]": [87,[18,20],[19]],
		"/participant/payouts": [88,[18],[19]],
		"/participant/projects": [89,[18],[19]],
		"/participant/projects/available": [90,[18,21],[19]],
		"/participant/projects/available/hidden": [91,[18,21],[19]],
		"/participant/projects/view/[project_id]": [92,[18,22],[19]],
		"/participant/projects/view/[project_id]/chatroom/[chatroom_id]": [93,[18,22,23],[19]],
		"/participant/projects/view/[project_id]/details": [94,[18,22],[19]],
		"/participant/refer": [95,[18],[19]],
		"/participant/settings": [96,[18],[19]],
		"/participant/settings/connect_refresh": [~97,[18],[19]],
		"/(auth)/participant/signup": [43,[2],[3]],
		"/participant/tech-stack": [98,[18],[19]],
		"/participant/tech-stack/create": [99,[18],[19]],
		"/participant/tech-stack/update/[stack_id]": [100,[18],[19]],
		"/researcher-login": [~130],
		"/researcher": [102,[24],[25]],
		"/researcher/app-reviews": [103,[24],[25]],
		"/researcher/app-reviews/view/[app_id]/details": [104,[24],[25]],
		"/(auth)/researcher/complete-profile": [~44,[2],[3]],
		"/researcher/dashboard": [105,[24],[25]],
		"/(auth)/researcher/login": [45,[2],[3]],
		"/researcher/messages": [106,[24,26],[25]],
		"/researcher/messages/[chatroom_id]": [107,[24,26],[25]],
		"/researcher/participants": [108,[24],[25]],
		"/researcher/participants/favorite": [109,[24],[25]],
		"/researcher/participants/view/[participant_id]/details": [110,[24],[25]],
		"/researcher/pricing": [111,[24],[25]],
		"/researcher/projects": [112,[24],[25]],
		"/researcher/projects/create": [113,[24,27],[25]],
		"/researcher/projects/create/[id]": [114,[24,27],[25]],
		"/researcher/projects/create/[id]/criteria": [115,[24,27],[25]],
		"/researcher/projects/create/[id]/details": [116,[24,27],[25]],
		"/researcher/projects/create/[id]/questions": [117,[24,27],[25]],
		"/researcher/projects/view/[project_id]": [118,[24,28],[25]],
		"/researcher/projects/view/[project_id]/details": [119,[24,28],[25]],
		"/researcher/projects/view/[project_id]/messages": [120,[24,28],[25]],
		"/researcher/projects/view/[project_id]/participants": [121,[24,28],[25]],
		"/researcher/projects/view/[project_id]/participants/[application_id]": [122,[24,28,29],[25]],
		"/researcher/projects/view/[project_id]/participants/[application_id]/chatroom": [123,[24,28,29,30],[25]],
		"/researcher/projects/view/[project_id]/participants/[application_id]/chatroom/[chatroom_id]": [124,[24,28,29,30],[25]],
		"/researcher/projects/view/[project_id]/payments": [125,[24,28],[25]],
		"/researcher/settings": [126,[24],[25]],
		"/(auth)/researcher/signup": [46,[2],[3]],
		"/researcher/team": [~127,[24],[25]],
		"/researcher/tech-stack/[participant_id]": [128,[24],[25]],
		"/researcher/transactions": [129,[24],[25]],
		"/share/[project_id]": [131,[],[31]],
		"/share/[project_id]/[referral_code]": [132,[],[31,32]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';